#search-nav-form .ui.grid .row.mini {
  font-size: xx-small;
}
#search-nav-form .ui.grid .row.tiny {
  font-size: x-small;
}
#search-nav-form .ui.grid .row.small {
  font-size: smaller;
}
#search-nav-form .ui.grid .row.large {
  font-size: small;
  line-height: 20px;
}
#search-nav-form .ui.grid .row.huge {
  font-size: large;
  line-height: 25px;
}
#search-nav-form .ui.grid .row.massive {
  font-size: x-large;
  line-height: 30px;
}
