.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 200px;
  padding: 20px;
  color: white;
}

.App-intro {
  margin-top: 2rem;
  font-size: large;
}

.App-note {
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 1.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media print and (orientation: portrait) {
  body {
    width: 1280px;
    transform: scale(0.9);
  }
}

@media print and (orientation: landscape) {
  body {
    width: 1280px;
    transform: scale(0.9);
  }
  section {
    display: block;
    page-break-before: always;
  }
  pre code {
    page-break-after: always;
  }
  @page {
    margin-top: 0.4cm;
    margin: 0.5cm;
  }
  p a,
  code,
  pre {
    word-wrap: break-word;
  }
  img {
    display: none;
  }

  pre a[href]:after {
    content: '';
  }

  pre abbr[title]:after {
    content: '';
  }

  pre .ir a:after,
  pre a[href^='javascript:']:after,
  pre a[href^='#']:after {
    content: '';
  }

  .no-print {
    display: none;
  }
}
