.ui.form .disabled.field.readOnly {
  opacity: .80;
}

.ui.form .disabled.field.readOnly>.checkbox>label {
  opacity: .80;
  background-color: unset;
  color: black;
}

.ui.form .ui.pointing.floating.label {
  left: 10%;
  top: 3em;
}

.ui.form table tbody tr td {
  position: relative;
}
