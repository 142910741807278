.ui.form .disabled.field.readOnly {
  opacity: .80;
}

.ui.form .disabled.field.readOnly>label {
  opacity: .80;
}

.ui.form .disabled.field.readOnly>.ui.disabled.dropdown {
  border: none;
  background-color: unset;
  opacity: unset;
}

.ui.form .disabled.field.readOnly>.ui.disabled.dropdown>i.dropdown.icon {
  display: none;
}

.ui.form .ui.pointing.floating.label {
  left: 10%;
  top: 3em;
}

.ui.form table tbody tr td {
  position: relative;
}

.ui.form .ui.selection.dropdown>.search.icon {
  margin-top: -7px;
  opacity: .5;
}

.ui.form .ui.labeled.input .ui.dropdown {
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.ui.form .ui.dropdown div.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.ui.form .ui.dropdown.selection .item {
  display: flex;
}

.ui.form .ui.dropdown.selection .item .ui.image {
  display: inline-block;
}

.ui.form .ui.dropdown.selection .item .text {
  display: inline-block;
  white-space: nowrap;
  padding-right: 20px;
}
