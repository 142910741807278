td.ui-state-disabled {
  opacity: 1.0 !important;
}
.datepicker-saturday span.ui-state-default { /* disabled saturday */
  background-image: none;
  color: #1e90ff;
  border: 1px solid transparent;
}
.datepicker-saturday a.ui-state-default { /* enable saturday */
  color: #1e90ff;
}
.datepicker-sunday span.ui-state-default { /* disabled sunday */
  background-image: none;
  color: #ff00a8;
  border: 1px solid transparent;
}
.datepicker-sunday a.ui-state-default { /* enable sunday */
  color: #ff00a8;
}
.datepicker-holiday span.ui-state-default { /* disabled holiday */
  background-image: none;
  color: #ff00a8;
  border: 1px solid transparent;
}
.datepicker-holiday a.ui-state-default { /* enable holiday */
  color: #ff00a8;
}
