.ui.form .disabled.field.readOnly {
  opacity: 0.8;
}

.ui.form .disabled.field.readOnly > label {
  opacity: 0.8;
}

.ui.form .disabled.field.readOnly .ui.disabled.input {
  opacity: 0.8;
}

.ui.form .disabled.field.readOnly .ui.disabled.input > input {
  border: none;
  background-color: unset;
  color: black;
}

.ui.form .disabled.field.readOnly .ui.disabled.input > input::placeholder {
  opacity: 0;
}

.ui.form .disabled.field.readOnly textarea {
  border: none;
  resize: none;
}

.ui.form .disabled.field.readOnly .ui.disabled.input > .ui.label {
  border: none;
}

.ui.form .field.alignRight .ui.input > input {
  text-align: right;
}

.ui.form .ui.pointing.floating.label.input-error-label {
  left: 30%;
  top: 100%;
}

.ui.form table tbody tr td {
  position: relative;
}
