.ui.form .disabled.field.readOnly {
  opacity: .80;
}

.ui.form .ui.read-only.checkbox {
  opacity: .80;
  background-color: unset;
  color: black;
}

.ui.form .ui.read-only.checkbox>label {
  opacity: .80;
}
