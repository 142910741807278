.ui.grid.sticky_grid {
  position: relative;
  width: calc(100vw - 3.0rem);
  border-collapse: collapse;
  z-index: 0;
}
.ui.grid.sticky_grid>.row.thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.ui.grid.sticky_grid_xy {
  display: block;
  position: relative;
  width: calc(100vw - 3.0rem);
  margin-top: 0;
}

.ui.grid.sticky_grid_xy>.row.thead,
.ui.grid.sticky_grid_xy>.row.tbody {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  flex-wrap: nowrap;
}

.ui.grid.sticky_grid_xy>.row.thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.sticky_grid_xy.ui.celled.grid>.row.thead>.column
{
  background-color: #f9fafb;
}

.sticky_grid_xy.ui.sortable.table .thead .th:hover,
.sticky_grid_xy.ui.sortable.table .thead .th.sorted
{
  background-color: #f9fafb;
  color: rgba(0,0,0,.8);
}

.sticky_grid_xy.ui.celled.grid>.row.thead>.column
{
  border-top: 1px solid rgba(34,36,38,.1);
  border-bottom: 1px solid rgba(34,36,38,.1);
}

.sticky_grid_xy.ui.celled.grid>.row>.column:first-child
{
  border-left: 1px solid rgba(34,36,38,.1);
}

.sticky_grid_xy.ui.celled.grid>.thead>.column:last-child
{
  border-right: 1px solid rgba(34,36,38,.1);
}

.sticky_grid_xy.ui.celled.grid>.row.tbody>.column
{
  padding: 10px;
}

