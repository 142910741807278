.ui.form .ui.labeled.input .ui.search .ui.input input {
  margin: 0;
}

.ui.form .ui.labeled.input:not(.right) .ui.search .ui.input input {
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.ui.form .ui.labeled.input.right .ui.search .ui.input input {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.ui.form .ui.labeled.input.right .ui.label {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
