.ui.table.sticky_table {
  position: relative;
  width: calc(100vw - 3.0rem);
  border-collapse: collapse;
  z-index: 0;
  table-layout: fixed;
  font-size: small
}
.ui.table.sticky_table thead {
  z-index: 5;
}

.ui.table.sticky_table thead th {
  position: sticky;
  top: 0;
  z-index: 10;
}

.ui.table.sticky_table.mini {
  font-size: xx-small
}
.ui.table.sticky_table.tiny {
  font-size: x-small
}
.ui.table.sticky_table.small {
  font-size: small
}
.ui.table.sticky_table.large {
  font-size: medium
}
.ui.table.sticky_table.huge {
  font-size: large
}
.ui.table.sticky_table.massive {
  font-size: x-large
}

.ui.table.sticky_table.xy {
  display: block;
  position: relative;
  width: calc(100vw - 3.0rem);
  table-layout: fixed;
}

.ui.table.sticky_table.xy thead,
.ui.table.sticky_table.xy tbody {
  display: block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.ui.table.sticky_table.xy th,
.ui.table.sticky_table.xy td {
  width: 8.0rem;
}

.ui.table.sticky_table.xy tbody th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 5;
}

.ui.table.sticky_table.xy thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
}

.ui.table.sticky_table.xy thead th.blank {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
}

.ui.table.sticky_table thead tr th,
.ui.table.sticky_table tbody tr td
{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ui.table.sticky_table.xy.ui.sortable.table thead th:hover,
.ui.table.sticky_table.xy.ui.sortable.table thead th.sorted
{
  background-color: #f9fafb;
  color: rgba(0,0,0,.8);
}

.ui.table.sticky_table.xy.ui.celled.table tr td:first-child,
.ui.table.sticky_table.xy.ui.celled.table tr th:first-child
{
  border-left: 1px solid rgba(34,36,38,.1);
}

.ui.table.sticky_table.xy.ui.celled.table tr td:last-child,
.ui.table.sticky_table.xy.ui.celled.table tr th:last-child
{
  border-right: 1px solid rgba(34,36,38,.1);
}

.ui.table.sticky_table.xy.ui.celled.table tr th
{
  border-top: 1px solid rgba(34,36,38,.1);
}

.ui.table.sticky_table.xy.ui.celled.table tr:last-child td
{
  border-bottom: 1px solid rgba(34,36,38,.1);
}

