.ui.form.gridForm .field>label,
.ui.form.gridForm .inline.fields .field>label
{
  display: none;
}

.ui.form .field
{
  margin-bottom: 0;
}

